import React, { useEffect, useState, useCallback } from "react";  // Import useEffect and useState
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDown, CheckCircle, ArrowRight, Zap, PenTool, Users, Send, Facebook, Twitter, Instagram, Linkedin, GitCompare} from 'lucide-react'
import { Button } from "../comp/ui/button"
import { FileText, Download, Edit, Search, MessageCircle, Star } from 'react-feather';
import { Input } from "../comp/ui/input"
import GoogleBlack from "../img/google.png"
import comp from '../img/comp.png';
import Microsoft from "../img/microsoft.jpg"
import Mckinsey from "../img/mckinsey.png"
import Amazon from "../img/amazon.png"
import Facebook2 from "../img/facebook copy.png"
import Illustraionhome from "../img/online-resume-black.png"
import ShootingStarButton from '../comp/ui/ShootingStarButton';
import { supabase } from '../supabaseClient';
import { Helmet } from 'react-helmet';
import NewHeader from '../comp/newheader'
import NewFooter from "../comp/NewFooter";
import { Link } from "react-router-dom";
import HeroSection from '../comp/HeroSection/HeroSection';
import PurpleFooter from '../comp/PurpleFooter';
import PurpleHeader from '../comp/PurpleHeader';

const companies = [
  { name: 'Google', logo: GoogleBlack },
  { name: 'Microsoft', logo: Microsoft },
  { name: 'Mckinsey', logo: Mckinsey },
  { name: 'Amazon', logo: Amazon },
  { name: 'Facebook', logo: Facebook2 },
];

const features = [
  { 
    title: 'Ohio University Business Resume Builder', 
    description: 'Create professional resumes following the Ohio University College of Business template.', 
    icon: FileText 
  },
  { 
    title: 'Multi-format Export', 
    description: 'Export your resume in PDF and Word document formats for versatile usage.', 
    icon: Download 
  },
  { 
    title: 'ATS-Friendly Resume Creation', 
    description: 'Ensure your resume passes through Applicant Tracking Systems with our optimized formats.', 
    icon: CheckCircle 
  },
  { 
    title: 'AI-Powered Bullet Point Writer', 
    description: 'Generate impactful bullet points for your experiences using advanced AI technology.', 
    icon: Edit 
  },
  { 
    title: 'AI Resume-Job Comparison', 
    description: 'Compare and optimize your resume against specific job descriptions using AI analysis.', 
    icon: GitCompare 
  },
  { 
    title: 'AI-Driven Job Search', 
    description: 'Leverage AI to find the most relevant job opportunities tailored to your profile.', 
    icon: Search 
  },
  { 
    title: 'AI Interview Preparation', 
    description: 'Prepare for interviews with AI-generated questions and guidance based on your resume and target job.', 
    icon: MessageCircle 
  },
  { 
    title: 'Resume Scoring and AI Suggestions', 
    description: 'Receive an AI-generated score for your resume along with tailored improvement suggestions.', 
    icon: Star 
  }
];
const faqs = [
  {
    question: 'How does the Ohio University Business Resume Builder work?',
    answer: 'Our resume builder is specifically designed to follow the Ohio University College of Business template. It guides you through inputting your information in a structured format, ensuring your resume aligns with the college recommended style and content.'
  },
  {
    question: 'What file formats can I export my resume to?',
    answer: 'You can export your resume to both PDF and Word document (DOCX) formats. This allows you to have a professional-looking PDF for digital applications and a Word document for easy editing if needed.'
  },
  {
    question: 'How does the AI-powered bullet point writer assist me?',
    answer: 'Our AI bullet point writer analyzes the information you provide about your experiences and generates impactful, concise bullet points. It helps you highlight your achievements and skills in a way that resonates with potential employers.'
  },
  {
    question: 'What makes the resumes created on this platform ATS-friendly?',
    answer: 'Our system uses optimized formatting and keywords that are easily readable by Applicant Tracking Systems (ATS). This increases the chances of your resume successfully passing through automated screening processes used by many employers.'
  },
  {
    question: 'How does the AI resume-job comparison feature work?',
    answer: 'When you input a job description, our AI analyzes it against your resume, identifying matching skills and experiences. It then provides suggestions on how to tailor your resume to better align with the specific job requirements.'
  },
  {
    question: 'Can the AI-driven job search find opportunities specific to my field and experience?',
    answer: 'Yes, our AI job search feature uses your resume content and preferences to find relevant job openings. It considers your skills, experience level, and career goals to match you with suitable opportunities.'
  },
  {
    question: 'How does the AI interview preparation tool function?',
    answer: 'Based on your resume and the job you are applying for, our AI generates potential interview questions and provides guidance on how to answer them effectively. This helps you prepare for both general and role-specific questions.'
  },
  {
    question: 'What does the resume scoring feature evaluate?',
    answer: 'Our AI-powered resume scoring system evaluates various aspects of your resume, including content relevance, formatting, keyword usage, and overall impact. It provides a comprehensive score along with specific suggestions for improvement in each area.'
  },
  {
    question: 'Is my personal information and resume data secure?',
    answer: 'Yes, we prioritize the security of your data. We use industry-standard encryption and security measures to protect your personal information and resume content. Your data is only used to provide and improve our services.'
  },
  {
    question: 'Can I use this platform if Im not a student at Ohio University?',
    answer: 'While our resume builder is tailored to the Ohio University College of Business template, anyone can use our platform. The AI-powered features and job search tools are valuable for all job seekers, regardless of their educational background.'
  }
];

const HeroIllustration = () => (
  <svg className="w-full h-auto max-w-xl mx-auto" viewBox="0 0 400 300" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="200" cy="150" r="150" fill="hsl(120, 20%, 85%)" />
    <rect x="120" y="80" width="160" height="200" rx="10" fill="hsl(120, 40%, 35%)" />
    <rect x="140" y="100" width="120" height="20" rx="5" fill="hsl(120, 20%, 95%)" />
    <rect x="140" y="130" width="120" height="10" rx="5" fill="hsl(120, 20%, 95%)" />
    <rect x="140" y="150" width="120" height="10" rx="5" fill="hsl(120, 20%, 95%)" />
    <rect x="140" y="170" width="120" height="10" rx="5" fill="hsl(120, 20%, 95%)" />
    <rect x="140" y="190" width="80" height="10" rx="5" fill="hsl(120, 20%, 95%)" />
    <circle cx="320" cy="60" r="30" fill="#267326" />
    <path d="M310 60L318 68L330 56" stroke="hsl(120, 20%, 95%)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default function HomePage() {
  const [expandedFaq, setExpandedFaq] = useState(null)

  const handleFaqToggle = useCallback((index) => {
    setExpandedFaq(prevIndex => prevIndex === index ? null : index)
  }, [])

 

  const [isLoggedIn, setIsLoggedIn] = useState(false);

useEffect(() => {
  const checkUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setIsLoggedIn(!!user);
  };

  checkUser();
}, []);

const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const { data, error } = await supabase
        .from('waitlist_emails')
        .insert([{ email: email }]);

      if (error) throw error;

      setIsSubmitted(true);
    } catch (error) {
      if (error.code === '23505') { // Unique constraint error code
        setError('This email is already subscribed.');
      } else {
        setError('An error occurred. Please try again.');
      }
      console.error('Error inserting email:', error);
    }
  };


  return (
    <div className="min-h-screen bg-background text-foreground flex flex-col" style={{ fontFamily: "Poppins" }}>
      <Helmet>
  <title>Career Redesigned - Create Easy and Great Resumes All in One Place</title>
  <link rel="icon" href="" />
</Helmet>

    <PurpleHeader/>
    <HeroSection />


      <main className="flex-grow">
       
      <section className="mb-8">
          <div className="mx-auto">
            <h2
              className="leading-[24px] tracking-[2px] text-xl font-semibold text-center mb-10" >
              TRUSTED BY PROFESSIONALS FROM TOP COMPANIES
            </h2>
            <div className="flex justify-center items-center flex-wrap gap-8 marquee">
              <div className="marquee-content">
                <img src={comp} className="marquee-image m-2 mr-8 h-10" />
                <img src={comp} className="marquee-image m-2 h-10" />
              </div>
            </div>
          </div>
        </section>

      

        <section id="features" className="container mx-auto px-4 py-20" >
          <h2 className="text-3xl font-bold text-center mb-12 text-foreground">
            Powerful Features to Boost Your Career
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8" >
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-card p-6 rounded-lg shadow-lg border"
                style={{borderColor:'#EEEEEE'}}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <feature.icon className="text-primary mb-4" size={24} />
                <h3 className="text-xl font-semibold mb-2 text-card-foreground">{feature.title}</h3>
                <p className="text-muted-foreground">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

       
        <section id="faq" className="py-20 bg-white">
  <div className="container mx-auto px-4 max-w-6xl">
    <h2 className="text-3xl font-bold text-center mb-12">
      Frequently Asked Questions
    </h2>
    <div className="grid md:grid-cols-2 gap-x-8 gap-y-4">
      {faqs.map((faq, index) => (
        <div key={index} className="border-b border-gray-200">
          <button
            className="flex justify-between items-center w-full py-5 text-left focus:outline-none"
            onClick={() => handleFaqToggle(index)}
            aria-expanded={expandedFaq === index}
          >
            <span className="text-lg font-medium text-gray-700">{faq.question}</span>
            <ChevronDown
              className={`flex-shrink-0 ml-2 transform transition-transform duration-200 ${
                expandedFaq === index ? 'rotate-180' : ''
              }`}
            />
          </button>
          <AnimatePresence>
            {expandedFaq === index && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="overflow-hidden"
              >
                <div className="pb-5 text-gray-600">
                  <p>{faq.answer}</p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  </div>
</section>
<section className="flex flex-col items-center px-5 md:px-32 max-md:px-5 my-10">
          <div
            className="flex flex-wrap gap-10 justify-between items-center p-8 md:p-16 max-w-[1170px] max-md:px-5 box-shadow rounded-3xl"
            style={{ border: "1px solid #dddddd" }}
          >
            <div className="flex flex-wrap self-stretch my-auto min-w-[240px] max-md:max-w-full">
              <div className="flex flex-col tracking-tight min-w-[240px] w-[568px] max-md:max-w-full">
                <h2 className="w-full text-2xl font-bold max-md:max-w-full">
                  Stay Updated with Our Latest Features!
                </h2>
                <p className="w-full text-l font-medium max-md:max-w-full">
                  Sign up for our newsletter and be the first to know about new resume-building
                  tools and tips
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-end self-stretch my-auto text-sm leading-tight text-center min-w-[320px] w-[276px]">
              {!isSubmitted ? (
                <form className="max-w-md mx-auto flex gap-4" onSubmit={handleSubscribe}>
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="flex-grow min-w-[350px]"
                    required
                  />
                  <Button className="rounded-full py-5 px-6 md:px-9 lg:px-9 min-h-[54px] rounded-[45px] gradient" type="submit">Subscribe</Button>
                </form>
              ) : (
                <p className="text-xl text-black font-semibold">Thank you for subscribing!</p>
              )}
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
          </div>
        </section>
      </main>

      <PurpleFooter />
      
    </div>
  )
}
