import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { sendToResumeAnalysisAI } from '../comp/resumeAnalysisAI';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpgradePopup from '../comp/UpgradePopup';
import Sidebar from '../comp/Sidebar';
import { Link } from 'react-router-dom';
import { Checkbox } from '../comp/ui/checkbox';

// Import UI components
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../comp/ui/card'; // Adjust the import path
import { RadioGroup, RadioGroupItem } from '../comp/ui/radio-group';
import { Label } from '../comp/ui/label';
import { Button } from '../comp/ui/button';
import { Input } from '../comp/ui/input';
import { Textarea } from '../comp/ui/textarea';
import { ChevronDown, LogOut } from 'lucide-react'; // Adjust if necessary
import { CheckCircle, BarChart2, BookOpen, GraduationCap, FileCheck, HelpCircle } from "lucide-react"

const CompareResume = () => {
  const [selectedResume, setSelectedResume] = useState(null);
  const [resumes, setResumes] = useState([]);
  const [resumeLimit, setResumeLimit] = useState(10);
  const [jobName, setJobName] = useState('');
  const [jobCompany, setJobCompany] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [user, setUser] = useState(null); // Initialize state to store the user
  const [isOpen, setIsOpen] = useState(false); // Sidebar state

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    fetchResumes();
  }, [resumeLimit]);

  const fetchResumes = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
      .from('resumes')
      .select('*')
      .eq('user_id', user.id)
      .eq('visibility', true)
        .order('created_at', { ascending: false })
        .limit(resumeLimit);

      if (error) throw error;
      setResumes(data);
    } catch (error) {
      console.error('Error fetching resumes:', error);
      toast.error('Failed to load resumes. Please try again.');
    }
  };

  const handleResumeSelect = (resumeId) => {
    setSelectedResume(resumeId);
  };

  const handleLoadMoreResumes = () => {
    setResumeLimit((prevLimit) => prevLimit + 10);
  };

  const isFormComplete = () => {
    return selectedResume && jobName && jobCompany && jobDescription;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isFormComplete()) {
      toast.error("Please complete all fields and select a resume.");
      return;
    }

    setIsLoading(true);
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;

      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) {
        throw new Error('User not found in the database');
      }

      const userId = userData.id;

  
      const resumeData = await fetchResumeData(selectedResume);
      const jobDetails = {
        title: jobName,
        company: jobCompany,
        description: jobDescription,
      };
  
      const analysisResult = await sendToResumeAnalysisAI(resumeData, jobDetails);
  
      // Generate unique code
      const uniqueCode = Math.random().toString(36).substring(2, 15);
  
      // Save analysis result to Supabase
      const { error } = await supabase
        .from('resume_comparisons')
        .insert([{
          user_id: userId,
          resume_id: selectedResume,
          resume_code: resumeData.code, // Add this line to save the resume code
          job_details: jobDetails,
          analysis_result: analysisResult,
          created_at: new Date().toISOString(),
          unique_code: uniqueCode,
        }]);
  
      if (error) throw error;
  
      toast.success("Resume comparison completed successfully!");
      navigate(`/comparison-result?code=${uniqueCode}`);
    } catch (error) {
      console.error('Error comparing resume:', error);
      toast.error(error.message || 'Failed to compare resume. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchResumeData = async (resumeId) => {
    const { data, error } = await supabase
      .from('resumes')
      .select('*')
      .eq('id', resumeId)
      .single();

    if (error) throw error;
    return data;
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Error logging out.');
    }
  };

  

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100" style={{ fontFamily: "Poppins" }}>
      {/* Sidebar */}
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">

        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          {isLoading && (
            <div className="spinner-overlay">
              <div className="spinner"></div>
            </div>
          )}
          <div className="container mx-auto p-4 ">
            <header className="text-center mb-4">
              <h1 className="text-3xl font-bold mb-2">Resume Match: Tailor Your Application</h1>
              <p className="text-gray-600">
              Maximize your chances by aligning your resume with the job requirements.
              </p>
            </header>

            <div className="flex justify-center items-center p-4">
      <Card className="w-full max-w-6xl">
        <CardContent className="grid md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <h3 className="text-xl font-semibold flex items-center gap-2">
              <BarChart2 className="h-6 w-6 text-primary" />
              What We'll Compare
            </h3>
            <ul className="space-y-2">
              {[
                "Keyword match",
                "Skills alignment",
                "Experience relevance",
                "Education fit",
                "Overall ATS compatibility",
              ].map((item, index) => (
                <li key={index} className="flex items-center gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-xl font-semibold flex items-center gap-2">
              <HelpCircle className="h-6 w-6 text-primary" />
              Why Compare?
            </h3>
            <ul className="space-y-2">
              {[
                { text: "Understand how well your resume aligns with the job requirements", icon: FileCheck },
                { text: "Identify gaps in your qualifications", icon: BarChart2 },
                { text: "Get suggestions for resume improvements", icon: BookOpen },
                { text: "Increase your chances of passing ATS screenings", icon: GraduationCap },
              ].map((item, index) => (
                <li key={index} className="flex items-center gap-2">
                  <item.icon className="h-5 w-5 text-primary" />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        </CardContent>
        <CardContent>
          <p className="text-sm text-muted-foreground italic mt-4">
            <strong>Pro Tip:</strong> Compare multiple versions of your resume to find the best match for each job application!
          </p>
        </CardContent>
      </Card>
    </div>

            <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row gap-8">
            <div className="w-full lg:w-1/2">
                <Card className="h-full">
                  <CardHeader>
                    <CardTitle>Select Your Resume</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <RadioGroup value={selectedResume || ""} onValueChange={handleResumeSelect}>
                      {resumes.map((resume) => (
                        <div key={resume.id} className="flex items-start space-x-2 mb-4">
                          <RadioGroupItem value={resume.id} id={`resume-${resume.id}`} />
                          <Label htmlFor={`resume-${resume.id}`} className="flex-grow">
                            <p className="block text-l font-large text-black" style={{fontSize:'16px'}}>{resume.name || 'Untitled Resume'}</p>
                            <p className="text-sm text-gray-500">
                              Created: {new Date(resume.created_at).toLocaleDateString()} 
                            </p>
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                    {resumes.length >= resumeLimit && (
                      <Button
                        type="button"
                        variant="outline"
                        className="mt-4 w-full"
                        onClick={handleLoadMoreResumes}
                      >
                        Load More Resumes <ChevronDown className="ml-2 h-4 w-4" />
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div className="w-full lg:w-1/2">
                <Card className="h-full">
                  <CardHeader>
                    <CardTitle>Job Details</CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="jobName">Job Title</Label>
                      <Input
                        id="jobName"
                        value={jobName}
                        onChange={(e) => setJobName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="jobCompany">Company</Label>
                      <Input
                        id="jobCompany"
                        value={jobCompany}
                        onChange={(e) => setJobCompany(e.target.value)}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="jobDescription">Job Description</Label>
                      <Textarea
                        id="jobDescription"
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                        className="min-h-[150px] h-24"
                        required
                      />
                    </div>
                  </CardContent>
                </Card>
              </div>
            </form>

            <Button
              type="submit"
              className="w-full mt-8"
              onClick={handleSubmit}
              disabled={!isFormComplete() || isLoading}
            >
              {isLoading ? 'Comparing...' : 'Compare Resume to Job'}
            </Button>
          </div>
        </main>
      </div>
      <ToastContainer />
      {showPopup && (
        <UpgradePopup
          message={popupMessage}
          onClose={() => setShowPopup(false)}
          onUpgrade={() => {
            console.log('Upgrading plan');
            setShowPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default CompareResume;
