import {
  Document,
  Paragraph,
  TextRun,
  AlignmentType,
  HeadingLevel,
  TabStopType,
  Footer,
  PageNumber,
  Packer,
} from 'docx';
import { saveAs } from 'file-saver';
import { parse, format } from 'date-fns';

const formatDate2 = (dateString) => {
  console.log('Input dateString:', dateString);

  if (!dateString) return '';
  
  try {
    // Handle array input
    const date = Array.isArray(dateString) ? dateString[0] : dateString;
    
    // Split the date string and create date properly
    const [year, month] = date.split('-');
    const parsedDate = new Date(parseInt(year), parseInt(month) - 1);
    
    const formattedDate = format(parsedDate, 'MMMM yyyy');
    
    return formattedDate;
  } catch (error) {
    return Array.isArray(dateString) ? dateString[0] : dateString;
  }
};

// Enhanced formatDate function
const formatDate = (dateInput) => {
  // Return empty string for null/undefined inputs
  if (!dateInput) return '';

  try {
    // Case 1: Handle array format (existing format)
    if (Array.isArray(dateInput)) {
      if (dateInput.length === 0) return '';
      const dateString = dateInput[0];
      if (typeof dateString !== 'string') return '';
      // Parse date in yyyy-MM format
      const parsedDate = parse(dateString, 'yyyy-MM', new Date());
      return format(parsedDate, 'MMMM yyyy');
    }

    // Case 2: Handle ISO date string (new format)
    if (typeof dateInput === 'string') {
      // Check if it's an ISO date string
      if (dateInput.includes('T')) {
        const parsedDate = new Date(dateInput);
        // Check if the date is valid
        if (isNaN(parsedDate.getTime())) return '';
        return format(parsedDate, 'MMMM yyyy');
      }
      
      // Case 3: Handle simple yyyy-MM format (fallback for existing format)
      const parsedDate = parse(dateInput, 'yyyy-MM', new Date());
      return format(parsedDate, 'MMMM yyyy');
    }

    return '';
  } catch (error) {
    console.error('Error parsing date:', error);
    return typeof dateInput === 'string' ? dateInput : ''; // Fallback to original string if parsing fails
  }
};


export const generateWord = (resumeData, hiddenSections, font = 'Times New Roman', fontSize = 20) => {
  try {

    console.log('Resume Data:', resumeData);
    const content = []; // Collect all paragraphs here

    // Define page margins (in twips)
    const pageMargin = {
      top: 720, // 0.5 inches
      bottom: 720, // 0.5 inches
      left: 720, // 0.5 inches
      right: 720, // 0.5 inches
    };

    // Calculate the tab stop position (page width - right margin)
    const tabStopPosition = 9000; // Approximate maximum position (you may adjust this based on page size)

    // Personal Information
    content.push(
      new Paragraph({
        children: [
          new TextRun({
            text: resumeData.personal_information?.name || '',
            bold: true,
            size: fontSize + 12, // Larger size for the name
            font, // Use dynamic font
            color: '000000', // Set color to black
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: { after: 100 }, // Reduce space after name
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: `${resumeData.personal_information?.phone || ''} | ${resumeData.personal_information?.email || ''} | ${resumeData.personal_information?.linkedin || ''}`,
            size: fontSize, // Use supplied font size
            font, // Use dynamic font
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: { after: 100 }, // Reduce space after contact info
      })
    );

    const addSection = (title, contentArray) => {
      content.push(
        new Paragraph({
          spacing: {
            before: 100, // Further reduced margin before section headers
            after: 50, // Reduced space after section headers
          },
          children: [
            new TextRun({
              text: title.toUpperCase(),
              bold: true,
              size: fontSize + 6, // Slightly larger size for section headers
              font, // Use dynamic font
              color: '000000', // Set color to black
            }),
          ],
          heading: HeadingLevel.HEADING_2,
          thematicBreak: true,
        }),
        ...contentArray
      );
    };

   // Modified joinArray function to handle dates
const joinArray = (value) => {
  if (Array.isArray(value)) {
    // Check if it's a date array (assuming date arrays have specific format)
    if (value.length > 0 && typeof value[0] === 'string' && 
        (value[0].match(/^\d{4}-\d{2}$/) || value[0].includes('T'))) {
      return formatDate(value);
    }
    return value.join(' ');
  }
  // Handle single date string
  if (typeof value === 'string' && 
      (value.match(/^\d{4}-\d{2}$/) || value.includes('T'))) {
    return formatDate(value);
  }
  return value || '';
};


    // Helper function to reduce spacing between entries
    const reduceSpacing = (paragraphs) => {
      return paragraphs.map((para) => {
        if (para instanceof Paragraph) {
          // Ensure options and spacing objects exist
          para.options = para.options || {};
          para.options.spacing = para.options.spacing || {};
          para.options.spacing.after = 50; // Reduce space after each paragraph
        }
        return para;
      });
    };

    // Render Sections in Order
    (resumeData.sectionOrder || [
      'education',
      'experience',
      'leadership',
      'awards',
      'certifications',
      'skills',
      ...(resumeData.customSections?.map((_, index) => `custom-${index}`) || []),
    ]).forEach((sectionName) => {
      if (hiddenSections?.[sectionName]) {
        return; // Skip hidden sections
      }

      switch (sectionName) {
        case 'education':
          // Education Section
          const educationContent = reduceSpacing(
            resumeData.education?.flatMap((edu) => [
              new Paragraph({
                spacing: { after: 50 },
                tabStops: [
                  { type: TabStopType.RIGHT, position: 12240 }, // Right margin alignment (8.5 inches)
                ],
                children: [
                  new TextRun({
                    text: `${joinArray(edu.university)} | ${joinArray(edu.location)}`,
                    bold: true,
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                  new TextRun({
                    text: '\t', // Tab to align the date to the right
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                  new TextRun({
                    text: `${formatDate(edu.graduation)}`, // Graduation date aligned to the right
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                ],
              }),
              new Paragraph({
                spacing: { after: 50 },
                children: [
                  new TextRun({
                    text: joinArray(edu.degree),
                    italics: true,
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                ],
              }),
              new Paragraph({
                spacing: { after: 50 },
                text: `Major: ${joinArray(edu.major)}`,
                font, // Use dynamic font
                size: fontSize, // Use dynamic font size
              }),
              new Paragraph({
                spacing: { after: 50 },
                text: `GPA: ${joinArray(edu.gpa)}`,
                font, // Use dynamic font
                size: fontSize, // Use dynamic font size
              }),
            ]) || []
          );
      
          addSection('Education', educationContent);
          break;
      
        case 'experience':
          // Experience Section
          const experienceContent = reduceSpacing(
            resumeData.experience?.flatMap((exp) => [
              new Paragraph({
                spacing: { after: 50 },
                tabStops: [
                  { type: TabStopType.RIGHT, position: 12240 }, // Right margin alignment (8.5 inches)
                ],
                children: [
                  new TextRun({
                    text: `${joinArray(exp.company)} | ${joinArray(exp.location)}`,
                    bold: true,
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                  new TextRun({
                    text: '\t', // Tab to align the date to the right
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                  new TextRun({
                    text: `${joinArray(exp.duration)}`, // Duration aligned to the right
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                ],
              }),
              new Paragraph({
                spacing: { after: 50 },
                children: [
                  new TextRun({
                    text: joinArray(exp.jobTitle),
                    italics: true,
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                ],
              }),
              ...((exp.description || []).map(
                (desc) =>
                  new Paragraph({
                    spacing: { after: 50 },
                    text: desc.startsWith('•') ? desc : `• ${desc}`,
                    indent: { left: 220 },
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  })
              )),
            ]) || []
          );
      
          addSection('Experience', experienceContent);
          break;
      
        case 'leadership':
          // Leadership Section
          const leadershipContent = reduceSpacing(
            resumeData.leadership?.flatMap((leader) => [
              new Paragraph({
                spacing: { after: 50 },
                tabStops: [
                  { type: TabStopType.RIGHT, position: 12240 }, // Right margin alignment (8.5 inches)
                ],
                children: [
                  new TextRun({
                    text: `${joinArray(leader.organization)} | ${joinArray(leader.location)}`,
                    bold: true,
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                  new TextRun({
                    text: '\t', // Tab to align the date to the right
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                  new TextRun({
                    text: `${joinArray(leader.duration)}`, // Duration aligned to the right
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                ],
              }),
              new Paragraph({
                spacing: { after: 50 },
                children: [
                  new TextRun({
                    text: joinArray(leader.title),
                    italics: true,
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                ],
              }),
              ...((leader.accomplishment || []).map(
                (acc) =>
                  new Paragraph({
                    spacing: { after: 50 },
                    text: acc.startsWith('•') ? acc : `• ${acc}`,
                    indent: { left: 220 },
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  })
              )),
            ]) || []
          );
      
          addSection('Leadership Activities and Memberships', leadershipContent);
          break;
      
        case 'awards':
          // Awards Section
          const awardsContent = reduceSpacing(
            resumeData.awards?.flatMap((award) => [
              new Paragraph({
                spacing: { after: 50 },
                tabStops: [
                  { type: TabStopType.RIGHT, position: 12240 },
                ],
                children: [
                  new TextRun({
                    text: `${joinArray(award.award)} | ${joinArray(award.location)}`,
                    bold: true,
                    font,
                    size: fontSize,
                  }),
                  new TextRun({
                    text: '\t',
                    font,
                    size: fontSize,
                  }),
                  new TextRun({
                    text: `${formatDate2(award.date)}`,
                    font,
                    size: fontSize,
                  }),
                ],
              }),
            ]) || []
          );
      
          addSection('Honors and Awards', awardsContent);
          break;
      
        case 'certifications':
          // Certifications Section
          const certificationsContent = reduceSpacing(
            resumeData.certifications?.flatMap((cert) => [
              new Paragraph({
                spacing: { after: 50 },
                tabStops: [
                  { type: TabStopType.RIGHT, position: 12240 }, // Right margin alignment (8.5 inches)
                ],
                children: [
                  new TextRun({
                    text: `${joinArray(cert.certification_name)} | ${joinArray(cert.certification_location)}`,
                    bold: true,
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                  new TextRun({
                    text: '\t', // Tab to align the year to the right
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                  new TextRun({
                    text: `${joinArray(cert.certification_year)}`, // Year aligned to the right
                    font, // Use dynamic font
                    size: fontSize, // Use dynamic font size
                  }),
                ],
              }),
            ]) || []
          );
      
          addSection('Certifications', certificationsContent);
          break;
      
        case 'skills':
          if (
            !hiddenSections?.skills &&
            (resumeData.skillsVisibility?.technical === 'yes' || resumeData.skillsVisibility?.languages === 'yes')
          ) {
            const skillsContent = reduceSpacing([]);
            if (resumeData.skillsVisibility?.technical === 'yes' && resumeData.skills?.technical) {
              skillsContent.push(
                new Paragraph({
                  spacing: { after: 50 },
                  children: [
                    new TextRun({ text: 'Technical:', bold: true, font, size: fontSize }),
                    new TextRun({ text: ` ${resumeData.skills.technical}`, font, size: fontSize }),
                  ],
                })
              );
            }
            if (resumeData.skillsVisibility?.languages === 'yes' && resumeData.skills?.languages) {
              skillsContent.push(
                new Paragraph({
                  spacing: { after: 50 },
                  children: [
                    new TextRun({ text: 'Language:', bold: true, font, size: fontSize }),
                    new TextRun({ text: ` ${resumeData.skills.languages}`, font, size: fontSize }),
                  ],
                })
              );
            }
            addSection('Technical and Language Skills', skillsContent);
          }
          break;

        default:
          // Custom Sections
          if (sectionName.startsWith('custom-')) {
            const index = parseInt(sectionName.split('-')[1]);
            const section = resumeData.customSections?.[index];
            if (section && !hiddenSections?.[`custom-${index}`]) {
              let contentLines = [];

              // Check the type of section.content
              if (typeof section.content === 'string') {
                // Split the content by newlines to handle multiple paragraphs
                contentLines = section.content.split('\n');
              } else if (Array.isArray(section.content)) {
                // If content is an array, use it directly
                contentLines = section.content;
              } else if (section.content == null) {
                // Content is undefined or null
                contentLines = [''];
              } else {
                // Content is some other type (number, object, etc.)
                contentLines = [String(section.content)];
              }

              const customContent = reduceSpacing(
                contentLines.map(
                  (line) =>
                    new Paragraph({
                      spacing: { after: 50 },
                      children: [
                        new TextRun({
                          text: line,
                          font, // Use dynamic font
                          size: fontSize, // Use dynamic font size
                        }),
                      ],
                    })
                )
              );
              addSection(section.title || '', customContent);
            }
          }
          break;
      }
    });

    // Footer with Page Numbers
    const footer = new Footer({
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun('Page '),
            new TextRun({
              children: [PageNumber.CURRENT],
            }),
            new TextRun(' of '),
            new TextRun({
              children: [PageNumber.TOTAL_PAGES],
            }),
          ],
        }),
      ],
    });

    // Create the document with default styles and narrow margins
    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              font, // Set default font family
              size: fontSize, // Set default font size
              color: '000000', // Set default text color to black
            },
          },
        },
      },
      sections: [
        {
          properties: {
            page: {
              margin: pageMargin,
            },
          },
          children: content,
          footers: {
            default: footer,
          },
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${resumeData.personal_information?.name || 'Resume'}.docx`);
    });
  } catch (error) {
    console.error('Error generating Word document:', error);
    alert('An error occurred while generating the Word document. Please try again.');
  }
};

export default generateWord;
