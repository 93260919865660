import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../comp/Sidebar'; // Adjust the path as needed
import { Link, useNavigate } from 'react-router-dom';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { processResumeData } from '../comp/AIParser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../supabaseClient'; // Adjust the path as needed

import { PenSquare, FileUp } from 'lucide-react'; // Adjust if necessary
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '../comp/ui/card'; // Adjust the path
import { Button } from '../comp/ui/button'; // Adjust the path
import {
  LogOut,
} from 'lucide-react'
import DashboardHeader from '../comp/Dashboardheader'; 

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Build = () => {
  const [resumeData, setResumeData] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [extractedText, setExtractedText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null); // Initialize state to store the user


  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);

  const [isOpen, setIsOpen] = useState(false); // Sidebar state

const toggleSidebar = () => setIsOpen(!isOpen);


  const navigate = useNavigate();

const handleLogout = async () => {
  const { error } = await supabase.auth.signOut(); // Perform logout using Supabase

  if (error) {
    console.error('Error logging out:', error.message);
  } else {
    toast.success('Logout successful!'); // Show success toast

    // Wait 3 seconds, then navigate to the login page
    setTimeout(() => {
      navigate('/login'); // Redirect to login page
    }, 3000);
  }
};



  // Add a ref for the file input
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size exceeds 5MB limit. Please upload a smaller file.");
        return;
      }

      // Check file type
      if (file.type !== 'application/pdf') {
        toast.error("Invalid file type. Please upload a PDF file.");
        return;
      }

      setFileUploaded(true);
      try {
        const text = await extractTextFromPDF(file);
        if (text.trim() === '') {
          throw new Error('No text extracted from PDF');
        }
        setExtractedText(text);
        console.log('Extracted text:', text);
      } catch (error) {
        console.error('Error extracting text from PDF:', error);
        toast.error("Unable to extract text from this PDF. Please use the resume form builder to create your resume.");
        setFileUploaded(false);
        setExtractedText('');
      }
    }
  };

  const extractTextFromPDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDocument = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;

    let extractedText = '';
    for (let i = 1; i <= pdfDocument.numPages; i++) {
      const page = await pdfDocument.getPage(i);
      const textContent = await page.getTextContent();
      const textItems = textContent.items.map(item => item.str).join(' ');
      extractedText += `${textItems}\n`;
    }

    return extractedText;
  };

  const saveExtractionToSupabase = async (extractedText, aiJsonOutput, resumeCode) => {
    console.log('Attempting to save extraction data...');
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error('Error fetching user:', userError);
        toast.error('Failed to fetch user data.');
        return;
      }
      const userId = user.id;
      console.log('User ID:', userId);

      const extractionData = {
        user_id: userId,
        resume_id: resumeCode,
        extracted_text: extractedText,
        ai_json_output: aiJsonOutput
      };
      console.log('Extraction data prepared:', extractionData);

      const { data, error } = await supabase
        .from('resume_extractions')
        .insert([extractionData]);

      if (error) {
        throw error;
      }

      console.log('Extraction data saved successfully:', data);
      toast.success("Extraction data has been saved successfully!");
    } catch (error) {
      console.error('Error saving extraction data:', error);
      toast.error("An error occurred while saving the extraction data. Please try again.");
    }
  };

  const sendToGoogleAI = async () => {
    if (!fileUploaded) {
      toast.error("Please upload a resume before submitting.");
      return;
    }

    setIsLoading(true);
    try {
      const processedData = await processResumeData(extractedText);
      if (processedData) {
        setResumeData(processedData);

        const resumeCode = await saveProcessedResumeToSupabase(processedData);

        if (resumeCode) {
          console.log('Saving extraction data...');
          await saveExtractionToSupabase(extractedText, processedData, resumeCode);

          toast.success("Resume saved! Redirecting to editor...");
          setTimeout(() => {
            window.location.href = `/resume-builder?code=${resumeCode}`;
          }, 2000);
        }
      } else {
        throw new Error("Failed to process resume data");
      }
    } catch (error) {
      console.error('Error processing resume:', error);
      setResumeData(null);
      toast.error("An error occurred while processing the resume. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const saveProcessedResumeToSupabase = async (processedData) => {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error('Error fetching user:', userError);
        toast.error('Failed to fetch user data.');
        return null;
      }
      const userId = user.id;
      const userEmail = user.email;

      const resumeName = `${processedData.name || 'Unnamed'}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`;

      const resumeData = {
        name: resumeName,
        code: generateUniqueCode(),
        user_id: userId,
        user_email: userEmail,
        personal_information: {
          name: processedData.name || '',
          phone: processedData.phone || '',
          email: processedData.email || '',
          linkedin: processedData.linkedin || ''
        },
        education: processedData.education || [],
        experience: processedData.experience || [],
        leadership: processedData.leadership || [],
        awards: processedData.awards || [],
        certifications: processedData.certifications || [],
        skills: processedData.skills || { technical: '', languages: '' },
        customSections: [],
        sectionOrder: ['education', 'experience', 'leadership', 'awards', 'certifications', 'skills'],
        last_updated: new Date().toISOString(),
        leadership_visibility: '',
        awards_visibility: '',
        skills_visibility: ''
      };

      const { data, error } = await supabase
        .from('resumes')
        .insert([resumeData])
        .select('code');

      if (error) {
        throw error;
      }

      console.log('Resume data saved successfully:', data);
      toast.success("Resume data has been saved successfully!");

      return data[0].code;
    } catch (error) {
      console.error('Error saving resume data:', error);
      toast.error("An error occurred while saving the resume data. Please try again.");
      return null;
    }
  };

  const generateUniqueCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeLength = 8;
    let code = '';

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  return (
    <div
    className="flex flex-col md:flex-row h-screen bg-gray-100"
    style={{ fontFamily: 'Poppins' }}
  >
    {/* Sidebar */}
    <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />

       {/* Main Content */}
       <div className="flex-1 flex flex-col overflow-hidden">
        <ToastContainer /> {/* Toastify container for showing notifications */}
        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {isLoading && (
            <div className="spinner-overlay">
              <div className="spinner"></div>
            </div>
          )}
          <div className="min-h-screen flex flex-col items-center p-4">
            <header className="text-center mb-8">
              <h1 className="text-4xl font-bold text-gray-800 mb-2">Resume Wizard</h1>
              <p className="text-xl text-gray-600">Your Gateway to Professional Success
</p>
            </header>

            <div className="grid gap-12 md:grid-cols-2 max-w-5xl w-full">
              <Card className="w-full">
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <PenSquare className="h-6 w-6" />
                    Create New Resume
                  </CardTitle>
                  <CardDescription>Start fresh with our AI-powered resume builder</CardDescription>
                </CardHeader>
                <CardContent>
                  <p className="block text-m font-medium text-black mb-8">
                  Our intelligent, step-by-step process guides you through creating a standout resume tailored to your industry and career goals.                  </p>
                  <Link to="/resume-builder">
                    <Button className="w-full items-center justify-center px-3 py-2 rounded-lg">Build My Resume</Button>
                  </Link>
                </CardContent>
              </Card>
              <Card className="w-full">
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <FileUp className="h-6 w-6" />
                    Upgrade Existing Resume
                                      </CardTitle>
                  <CardDescription>Import and transform your current resume</CardDescription>
                </CardHeader>
                <CardContent>
                  <p className="block text-m font-medium text-black mb-8">
                  Already have a resume? Let our AI enhance it with optimized formatting, powerful language, and industry-specific keywords.                  </p>
                  {/* Adjusted code starts here */}
                  <div className="flex flex-col items-center mb-8">
                    <Button
                      className="w-full mb-8"
                      onClick={() => fileInputRef.current && fileInputRef.current.click()}
                    >
                      Choose File
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="application/pdf"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    {fileUploaded && (
                      <p className="mt-2 text-sm text-green-600">File uploaded successfully!</p>
                    )}
                    <Button
                      type="button"
                      onClick={sendToGoogleAI}
                      className="w-full mt-4"
                      disabled={isLoading || !fileUploaded}
                    >
                      {isLoading ? 'Processing...' : 'Submit your Resume'}
                    </Button>
                  </div>
                  {/* Adjusted code ends here */}
                </CardContent>
              </Card>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Build;
