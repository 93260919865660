
import { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle } from "../comp/ui/card";
import ResumeItem from "../comp/ResumeItem";
import { Button } from "../comp/ui/button";
import { FileText, BarChart3, Search, MessageSquare, Plus, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import Sidebar from '../comp/Sidebar';
import { supabase } from "../supabaseClient";
import Steps1 from '../img/steps1.jpeg'
import Steps2 from '../img/steps2.jpeg'
import Steps3 from '../img/steps3.jpeg'
import Steps4 from '../img/steps4.jpeg'
import Steps5 from '../img/steps5.jpeg'
import Steps6 from '../img/steps6.jpeg'
import Steps7 from '../img/steps7.jpeg'
import Steps8 from '../img/steps8.jpeg'
import ProcessGuide from '../comp/ProcessGuide';
// Quick actions data
const quickActions = [
  {
    title: "Craft Your Story",
    icon: FileText,
    description: "Transform your experiences into a compelling professional narrative",
    link: "/builder"
  },
  {
    title: "Unlock Your Potential",
    icon: BarChart3,
    description: "Get data-driven insights to maximize your resume's impact.",
    link: "/compareResume"
  },
  {
    title: "Discover Opportunities",
    icon: Search,
    description: "Find your perfect role with AI-powered job matching.",
    link: "/jobprofile"
  },
  {
    title: "Ace Your Interviews",
    icon: MessageSquare,
    description: "Prepare like a pro with our AI-driven interview simulator.",
    link: "/myinterview"
  }
];

// Tutorial steps data
const tutorialSteps = [
  {
    title: "Career Command Center",
    content: `Welcome to your Career Command Center! This is your personal dashboard where you can access all the tools you need to advance your career. From here, you can: - Craft your story using Resume Genius. - Analyze your resume with Resume X-Ray. - Scout job opportunities with Opportunity Scout. - Master your interviews with Interview Master. Let's get started by choosing a tool!`,
    imageUrl: Steps1
  },
  {
    title: "Resume Genius - Create New or Upgrade Resume",
    content: `Ready to create or enhance your resume? In the Resume Genius section, you can either: - Build a new resume from scratch using our AI-powered builder, or - Upload and enhance an existing resume with optimized formatting and keyword improvements. Choose an option to get started on a resume that will stand out to recruiters!`,
    imageUrl: Steps2
  },
  {
    title: "Resume Editing Screen",
    content: `This is where the magic happens! Follow the step-by-step process to fill out each section of your resume: 1. Personal Information: Enter your contact details. 2. Education: Add your academic background. 3. Work Experience: List your professional experience. 4. Clubs & Awards: Highlight your extracurricular involvement and achievements. 5. Skills: Showcase your technical and language proficiencies. Once you're done, click 'Save' to finalize your resume!`,
    imageUrl: Steps3
  },
  {
    title: "Resume Completed - Save or Edit More",
    imageUrl: Steps4,
    content: `Congratulations! Your resume has been created. Now, you can either: - Save the resume to your account. - Download a PDF version to share with employers. - Or go back and make more edits. Remember, you can always come back and update your resume anytime.`,
  },
  {
    title: "Resume X-Ray - Tailor Your Application",
    imageUrl: Steps5,
    content: `Welcome to Resume X-Ray! This tool helps you tailor your resume to specific job postings. We will compare your resume's keywords, skills, experience, and education with the job description. Select a resume and enter the job title and description, then click 'Compare Resume to Job' for feedback. Maximize your chances of passing ATS screenings and impressing recruiters!`,
  },
  {
    title: "Opportunity Scout - AI Job Match",
    imageUrl: Steps6,
    content: `Discover your perfect job with Opportunity Scout. Our AI scans thousands of job postings in real-time to match you with roles that fit your skills and experience. Select the resume that best reflects your career goals and click 'Submit Resume' to get job matches tailored just for you!`,
  },
  {
    title: "Interview Master - AI Interview Prep",
    imageUrl: Steps7,
    content: `Prepare for your next big interview with Interview Master. - Practice with tailored questions based on your resume and job description. - Get real-time feedback to improve your responses. - Track your progress and boost your confidence before the interview day. Start fresh by choosing a job role to prepare for, and let our AI guide you through the process!`,
  },
  {
    title: "Account Settings",
    imageUrl: Steps8,
    content: `In your Account Settings, you can: - Update your personal information like name and email. - Change your password for added security. Make sure your details are always up to date!`,
  }
];

const QuickAction = ({ title, icon: Icon, description, link }) => (
  <div className="col-span-1 rounded-xl shadow-lg bg-card text-card-foreground flex flex-col h-[175px]">
    <CardHeader className="p-4">
      <CardTitle className="font-poppins">{description}</CardTitle>
    </CardHeader>
    <div className="p-4 mt-auto">
      <Link
        to={link}
        className="w-full text-white text-m flex items-center justify-center px-3 py-2 rounded-lg"
        style={{ backgroundColor: '#603CFF' }}>
        <Icon className="w-4 h-4 mr-2" />
        <span className="truncate">{title}</span>
      </Link>
    </div>
  </div>
);

const Dashboard2 = () => {
  const [showTutorial, setShowTutorial] = useState(false); // Manage the tutorial visibility
  const [currentStep, setCurrentStep] = useState(0); // Track the current tutorial step
  const [user, setUser] = useState(null); // Store the user info
  const [isOpen, setIsOpen] = useState(false); // Sidebar state

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        setUser(data.user);
      }
    };
    fetchUser();
  }, []);

  const handleNext = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowTutorial(false); // Close the tutorial when finished
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 font-poppins" style={{ fontFamily: "Poppins" }}>
      {/* Sidebar */}
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-8">
          <ProcessGuide />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        
            {/* Left Column: Quick Actions */}
            
            <div className="space-y-6">

            <div className="flex justify-between items-center">
            <h2 className="text-lg font-poppinsMedium">Quick Actions</h2>
              <Button className="text-white flex items-center justify-center px-3 py-2 rounded-lg"
 onClick={() => setShowTutorial(true)}>Start Tutorial</Button>


</div>
              
              {/* Custom Modal for Tutorial */}
              {showTutorial && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    {/* Modal Overlay */}
    <div className="fixed inset-0 bg-black bg-opacity-50" onClick={() => setShowTutorial(false)}></div>

    {/* Modal Content */}
    <div className="bg-white p-6 rounded-lg shadow-lg z-50 w-full relative" style={{maxWidth:'800px'}}>
      <button className="absolute top-4 right-4" onClick={() => setShowTutorial(false)}>
        <X className="w-5 h-5 text-gray-600" />
      </button>

      {/* Title */}
      <div className="mb-4">
        <h3 className="text-xl font-semibold">{tutorialSteps[currentStep].title}</h3>
      </div>

      {/* Image */}
      {tutorialSteps[currentStep].imageUrl && (
        <div className="mb-4">
          <img
            src={tutorialSteps[currentStep].imageUrl}
            alt={tutorialSteps[currentStep].title}
            className="w-full h-auto rounded-lg"
          />
        </div>
      )}

      {/* Content */}
      <p className="mt-2 text-black whitespace-pre-line">
        {tutorialSteps[currentStep].content}
      </p>

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-4">
        <Button onClick={handlePrevious} disabled={currentStep === 0}>
          Previous
        </Button>
        <Button onClick={handleNext}>
          {currentStep === tutorialSteps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </div>
    </div>
  </div>
)}

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {quickActions.map((action) => (
                  <QuickAction key={action.title} {...action} />
                ))}
              </div>
            </div>

            {/* Right Column: Your Resumes */}
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-poppinsMedium">Your Resumes</h3>
                <Link
                  to="/builder"
                  className="text-white flex items-center justify-center px-3 py-2 rounded-lg"
                  style={{ backgroundColor: '#603CFF' }}>
                  <Plus className="w-4 h-4 mr-2" />
                  New Resume
                </Link>
              </div>
              <div className="rounded-2xl box-shadow bg-card">
                <ResumeItem />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard2;
