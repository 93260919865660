// AIGenerator.js
import { GoogleGenerativeAI } from "@google/generative-ai";

// Load the Google API key from the environment variables
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

export async function generateBulletPoints(jobData) {

  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
    Given the following job information:
    Company: ${jobData.company}
    Job Title: ${jobData.jobTitle}
    Location: ${jobData.location}
    Duration: ${jobData.duration}
    Job Description: ${jobData.explanation}

    Generate 1-4 bullet points describing the job responsibilities and achievements. Each bullet point should include what was accomplished (X), the qualitative results (Y), and the skills or experience used to achieve the outcome (Z). The format should be sophisticated yet straightforward. Include numerical data where relevant.

    Return the bullet points as a JSON array of strings, with each string representing a single bullet point. Do not include any other text or formatting.
  `;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    // Parse the JSON array from the response
    const bulletPoints = JSON.parse(text);
    return bulletPoints;
  } catch (error) {
    console.error("Error generating bullet points:", error);
    return [];
  }
}