import { GoogleGenerativeAI } from "@google/generative-ai";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

export async function processResumeData(extractedText) {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
    Given the following resume text, extract and organize the information into the following JSON format:

    {
      "name": "",
      "phone": "",
      "email": "",
      "linkedin": "",
      "education": [
        {
          "university": "",
          "location": "",
          "graduation": "",
          "degree": "",
          "major": "",
          "gpa": ""
        }
      ],
      "experience": [
        {
          "company": "",
          "jobTitle": "",
          "location": "",
          "duration": "",
          "description": [""]
        }
      ],
      "leadership": [
        {
          "organization": "",
          "location": "",
          "duration": "",
          "title": "",
          "accomplishment": [""]
        }
      ],
      "awards": [
        {
          "award": "",
          "location": "",
          "date": ""
        }
      ],
      "certifications": [
        {
          "certification_name": "",
          "certification_location": "",
          "certification_year": ""
        }
      ],
      "skills": {
        "technical": "",
        "languages": ""
      }
    }

    Resume text:
    ${extractedText}

    Please fill in all available information from the resume into this JSON structure. If any field is not present in the resume, leave it as an empty string. For arrays, if there's no information, use an empty array []. Ensure that 'description' and 'accomplishment' are always arrays of strings. Return only the JSON object without any additional text or formatting. 
  `;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    let text = response.text();
    
    // Remove any markdown formatting or extra text
    text = text.replace(/```json\n|\n```/g, '').trim();
    
    // Attempt to parse the JSON
    try {
      const resumeData = JSON.parse(text);
      return resumeData;
    } catch (parseError) {
      console.error("Error parsing JSON:", parseError);
      console.log("Received text:", text);
      return null;
    }
  } catch (error) {
    console.error("Error processing resume data:", error);
    return null;
  }
}