import { supabase } from '../supabaseClient';

export const checkUsageLimit = async (featureType) => {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;

    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('email', user.email)
      .single();
    if (userDataError) throw userDataError;

    const userId = userData.id;

    // Fetch user's subscription
    const { data: subscriptionData, error: subscriptionError } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', userId)
      .maybeSingle();

    if (subscriptionError) throw subscriptionError;

    if (!subscriptionData) {
      throw new Error('No subscription found for this user');
    }

    // Fetch today's usage
    const today = new Date().toISOString().split('T')[0];
    let { data: usageData, error: usageError } = await supabase
      .from('usage_tracking')
      .select('*')
      .eq('user_id', userId)
      .eq('usage_date', today)
      .maybeSingle();

    if (usageError) throw usageError;

    let currentUsage = 0;
    let limit = 0;

    switch (featureType) {
      case 'ai_resume':
        currentUsage = usageData?.ai_resume_limit || 0;
        limit = subscriptionData.ai_resume_limit;
        break;
      case 'job_search':
        currentUsage = usageData?.job_search_limit || 0;
        limit = subscriptionData.job_search_limit;
        break;
      case 'interview_prep':
        currentUsage = usageData?.interview_prep_limit || 0;
        limit = subscriptionData.interview_prep_limit;
        break;
      default:
        throw new Error('Invalid feature type');
    }

    if (currentUsage >= limit) {
      return {
        canProceed: false,
        message: `You have reached your daily limit for ${featureType.replace('_', ' ')}. Please upgrade your plan to continue.`,
      };
    }

    // If we're here, the user can proceed. Let's update the usage.
    if (usageData) {
      // Update existing record
      const { error: updateError } = await supabase
        .from('usage_tracking')
        .update({ [featureType + '_limit']: currentUsage + 1 })
        .eq('id', usageData.id);

      if (updateError) throw updateError;
    } else {
      // Insert new record
      const { error: insertError } = await supabase
        .from('usage_tracking')
        .insert({
          user_id: userId,
          usage_date: today,
          [featureType + '_limit']: 1
        });

      if (insertError) throw insertError;
    }

    return { canProceed: true };
  } catch (error) {
    console.error('Error checking usage limit:', error);
    return {
      canProceed: false,
      message: 'An error occurred while checking your usage limit. Please try again.',
    };
  }
};