import React from 'react';

import { GoogleGenerativeAI } from "@google/generative-ai";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

export async function gradeResumeWithGoogleAI(resumeData) {
  try {
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

    const prompt = `Grade this resume and provide feedback. The resume data is: ${JSON.stringify(resumeData)}.

Please grade the resume based on the following criteria, with a total of 50 points possible:

1. Contact Information (5 points)
2. Format (5 points)
3. Spelling and Grammar (5 points)
4. Alignment and Consistency (5 points)
5. Reverse Chronological Order (5 points)
6. Education Section (5 points)
7. Bullet Points (20 points)

Provide the response in JSON format with the following structure:
{
  "studentName": "Name of the student",
  "score": A number from 0 to 50,
  "feedback": {
    "strengths": [
      "Strength point 1",
      "Strength point 2",
      ...
    ],
    "areasForImprovement": [
      "Area for improvement 1",
      "Area for improvement 2",
      ...
    ]
  },
  "breakdownScores": {
    "contactInformation": score (0-5),
    "format": score (0-5),
    "spellingAndGrammar": score (0-5),
    "alignmentAndConsistency": score (0-5),
    "reverseChronologicalOrder": score (0-5),
    "educationSection": score (0-5),
    "bulletPoints": score (0-20)
  }
}

Please ensure that the feedback in 'strengths' and 'areasForImprovement' directly relates to the grading criteria provided. Each point should be specific and actionable.`;

    const result = await model.generateContent(prompt);
    const response = await result.response;
    let text = response.text();

    // Clean the response: remove markdown formatting and find the JSON object
    text = text.replace(/```json\s?|\s?```/g, '');
    const jsonMatch = text.match(/\{[\s\S]*\}/);
    if (!jsonMatch) {
      throw new Error("No valid JSON found in the response");
    }

    let jsonText = jsonMatch[0];

    // Additional cleaning: remove any trailing commas in arrays or objects
    jsonText = jsonText.replace(/,\s*([\]}])/g, '$1');

    // Attempt to parse the cleaned JSON
    let gradeData;
    try {
      gradeData = JSON.parse(jsonText);
    } catch (parseError) {
      console.error("JSON parse error:", parseError);
      
      // If parsing fails, attempt to extract data manually
      const nameMatch = jsonText.match(/"studentName"\s*:\s*"([^"]+)"/);
      const scoreMatch = jsonText.match(/"score"\s*:\s*(\d+)/);
      const strengthsMatch = jsonText.match(/"strengths"\s*:\s*\[([\s\S]*?)\]/);
      const areasMatch = jsonText.match(/"areasForImprovement"\s*:\s*\[([\s\S]*?)\]/);

      gradeData = {
        studentName: nameMatch ? nameMatch[1] : "Unknown",
        score: scoreMatch ? parseInt(scoreMatch[1]) : 0,
        feedback: {
          strengths: [],
          areasForImprovement: []
        },
        breakdownScores: {}
      };

      if (strengthsMatch) {
        gradeData.feedback.strengths = strengthsMatch[1].split(',').map(item => 
          item.trim().replace(/^"/, '').replace(/"$/, '')
        ).filter(item => item.length > 0);
      }

      if (areasMatch) {
        gradeData.feedback.areasForImprovement = areasMatch[1].split(',').map(item => 
          item.trim().replace(/^"/, '').replace(/"$/, '')
        ).filter(item => item.length > 0);
      }
    }

    return gradeData;
  } catch (error) {
    console.error("Error in grading:", error);
    return {
      studentName: "Error",
      score: 0,
      feedback: {
        strengths: [],
        areasForImprovement: ["An error occurred while grading the resume. Please try again later."]
      },
      breakdownScores: {}
    };
  }
}