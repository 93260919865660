import React, { useState, useEffect } from 'react';
import Sidebar from "../comp/Sidebar"; // Adjust if needed
import { supabase } from "../supabaseClient"; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import HeaderWithIcon from '../comp/HeaderWithIcon';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../comp/ui/card'; // Adjust the import path
import { Button } from '../comp/ui/button';
import { Input } from '../comp/ui/input';
import { Label } from '../comp/ui/label';
import { Separator } from '../comp/ui/Separator';
import {
  LogOut,
} from 'lucide-react'

const Settings = () => {
  // State variables
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  const [isOpen, setIsOpen] = useState(false); // Sidebar state

  const toggleSidebar = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
  
      if (user) {
        // Fetch user details from the 'users' table using 'email'
        let { data, error } = await supabase
          .from('users')
          .select('name, id, email')
          .eq('email', user.email)
          .single();
  
        if (error) {
          if (error.code === 'PGRST116') {
            // User not found in the database
            console.log('User not found in database. Not creating a new entry.');
            // Set default values instead of creating a new entry
            data = { name: user.user_metadata?.full_name || '', id: null, email: user.email };
          } else {
            throw error;
          }
        }
  
        setUser({ ...user, dbId: data?.id }); // Store the database ID separately
        setName(data?.name || '');
        setEmail(user.email || '');
      }
    } catch (error) {
      toast.error('Failed to fetch user details: ' + error.message);
    }
  };

  const handlePersonalInfoSave = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
  
      let updatedEmail = email;
  
      // Update email in Auth if it has changed
      if (user.email !== email) {
        const { error: emailUpdateError } = await supabase.auth.updateUser({
          email: email,
        });
        if (emailUpdateError) throw emailUpdateError;
  
        toast.success('A confirmation link has been sent to your new email. Please confirm to complete the update.');
        
        // Keep using the old email for database update until confirmation
        updatedEmail = user.email;
      }
  
      // Check if user exists in the database
      const { data: existingUser, error: checkError } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();
  
      if (checkError && checkError.code !== 'PGRST116') {
        throw checkError;
      }
  
      if (!existingUser) {
        // User doesn't exist, create a new entry
        const { error: insertError } = await supabase
          .from('users')
          .insert({ email: updatedEmail, name: name });
  
        if (insertError) throw insertError;
      } else {
        // User exists, update the entry
        const { error: updateError } = await supabase
          .from('users')
          .update({ name: name, email: updatedEmail })
          .eq('email', user.email);
  
        if (updateError) throw updateError;
      }
  
      // Refresh user data
      await fetchUserDetails();
  
      toast.success('Profile updated successfully!');
    } catch (error) {
      toast.error('Failed to update profile: ' + error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    try {
      if (newPassword !== confirmPassword) {
        toast.error('New password and confirmation do not match.');
        return;
      }

      if (!currentPassword) {
        toast.error('Please enter your current password.');
        return;
      }

      setIsUpdating(true);

      // First, verify the current password
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: user.email,
        password: currentPassword,
      });

      if (signInError) {
        throw new Error('Current password is incorrect.');
      }

      // If current password is correct, update to the new password
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (updateError) throw updateError;

      toast.success('Password updated successfully!');

      // Clear password fields
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');

    } catch (error) {
      console.error('Error updating password:', error);
      toast.error(error.message || 'Failed to update password.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut(); // Perform logout using Supabase
  
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      toast.success('Logout successful!'); // Show success toast
  
      // Wait 3 seconds, then navigate to the login page
      setTimeout(() => {
        navigate('/login'); // Redirect to login page
      }, 3000);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100" style={{ fontFamily: "Poppins" }}>
      {/* Sidebar */}
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-3">
          <div className="container mx-auto p-4 max-w-7xl">
            <h1 className="text-3xl font-bold mb-6">Account Settings</h1>
            <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
              <Card>
                <CardHeader>
                  <CardTitle>Personal Information</CardTitle>
                  <CardDescription>Update your name and email address</CardDescription>
                </CardHeader>
                <CardContent>
                  <form onSubmit={handlePersonalInfoSave} className="space-y-4">
                    <div>
                      <Label htmlFor="name">Name</Label>
                      <Input
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <Label htmlFor="email">Email</Label>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <Button type="submit" disabled={isUpdating} className='w-full mt-4'>
                      {isUpdating ? 'Saving...' : 'Save Changes'}
                    </Button>
                  </form>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Change Password</CardTitle>
                  <CardDescription>Ensure your account is using a strong password</CardDescription>
                </CardHeader>
                <CardContent>
                  <form onSubmit={handlePasswordChange} className="space-y-4">
                    <div>
                      <Label htmlFor="current-password">Current Password</Label>
                      <Input
                        id="current-password"
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                      />
                    </div>
                    <Separator />
                    <div>
                      <Label htmlFor="new-password">New Password</Label>
                      <Input
                        id="new-password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <Label htmlFor="confirm-password">Confirm New Password</Label>
                      <Input
                        id="confirm-password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                    <Button type="submit" disabled={isUpdating} className='w-full mt-4'>
                      {isUpdating ? 'Changing...' : 'Change Password'}
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Settings;