import React, { useEffect, useState, useMemo } from 'react';
import { jsPDF } from 'jspdf';
import { parse, format } from 'date-fns';

const ResumePreviewWithPDF = ({ formData, hiddenSections, font, fontSize }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  
  const dummyData = {
    name: 'John Doe',
    phone: '(123) 456-7890',
    email: 'john.doe@example.com',
    linkedin: 'https://linkedin.com/in/johndoe',
    education: [{
      university: 'Ohio University, College of Business',
      location: 'Athens, Ohio',
      graduation: 'May 2025',
      degree: 'Bachelor of Business Administration',
      major: 'List all majors',
      gpa: '3.0'
    }],
    experience: [{
      company: 'Name of Company',
      jobTitle: 'Title',
      location: 'City, State',
      duration: 'Month Year – Present',
      description: ['Sample job description']
    }],
    leadership: [{
      organization: 'Organization',
      location: 'City, State',
      duration: 'Month Year – Present',
      title: 'Title',
      accomplishment: ['Sample accomplishment']
    }],
    awards: [{
      award: 'Award/Title/Scholarship',
      location: 'City, State',
      date: 'May 2023'
    }],
    certifications: [{
      certification_name: 'Certification Name',
      certification_location: 'Issuer Name',
      certification_year: 'Year'
    }],
    skills: {
      technical: 'Listed proficiency in software programming (Do not list Microsoft Office products)',
      languages: 'Listed proficiency in languages including level of mastery (conversational, fluent)'
    },
    skillsVisibility: { technical: true, languages: true },
    customSections: [],
    sectionOrder: ['education', 'experience', 'leadership', 'awards', 'certifications', 'skills']
  };

  const combinedData = useMemo(() => {
    const combined = JSON.parse(JSON.stringify(dummyData));
    Object.keys(formData).forEach(key => {
      if (key === 'personal_information') {
        combined.name = formData.personal_information.name || combined.name;
        combined.phone = formData.personal_information.phone || combined.phone;
        combined.email = formData.personal_information.email || combined.email;
        combined.linkedin = formData.personal_information.linkedin || combined.linkedin;
      } else if (key === 'sectionOrder') {
        combined.sectionOrder = formData.sectionOrder || combined.sectionOrder;
      } else if (typeof formData[key] === 'object' && formData[key] !== null) {
        if (Array.isArray(formData[key])) {
          combined[key] = formData[key].map((item, index) => {
            const dummyItem = combined[key] && combined[key][index] ? combined[key][index] : {};
            return Object.keys(item).reduce((acc, itemKey) => {
              acc[itemKey] = item[itemKey] || dummyItem[itemKey] || '';
              return acc;
            }, {});
          });
        } else {
          combined[key] = { ...combined[key], ...formData[key] };
        }
      } else {
        combined[key] = formData[key] || combined[key];
      }
    });
    return combined;
  }, [formData]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const parsedDate = new Date(dateString);
      return format(parsedDate, 'MMMM yyyy');
    } catch (error) {
      console.error('Error parsing date:', error);
      return dateString;
    }
  };

  const formatDate2 = (dateString) => {
  
    if (!dateString) return '';
    
    try {
      // Handle array input
      const date = Array.isArray(dateString) ? dateString[0] : dateString;
      
      // Split the date string and create date properly
      const [year, month] = date.split('-');
      const parsedDate = new Date(parseInt(year), parseInt(month) - 1);
      
      const formattedDate = format(parsedDate, 'MMMM yyyy');
      
      return formattedDate;
    } catch (error) {
      return Array.isArray(dateString) ? dateString[0] : dateString;
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const lineHeight = 5;
    const leftMargin = 10;
    const rightMargin = 210 - leftMargin;
    const pageWidth = doc.internal.pageSize.width;
    const marginfontsize = parseInt(fontSize);

    // Map of font selections
    const jsPdfFontMap = {
      'Helvetica, sans-serif': 'helvetica',
      'Times New Roman, serif': 'times',
      'Courier New, monospace': 'courier'
    };
  
    const selectedFont = jsPdfFontMap[font] || 'helvetica'; // Fallback to Helvetica if unsupported
  
    // Set font and size
    doc.setFont(selectedFont);
    doc.setFontSize(fontSize ? parseInt(fontSize, 10) : 12);
  
    // Personal Information
    doc.setFontSize(fontSize ? parseInt(fontSize, 10) + 4 : 16); // Larger for name
    doc.text(combinedData.name, pageWidth / 2, 15, { align: "center" });
    doc.setFontSize(fontSize ? parseInt(fontSize, 10) : 12); // Reset to normal size
    const contactInfo = `${combinedData.phone} | ${combinedData.email} | ${combinedData.linkedin}`;
    doc.text(contactInfo, pageWidth / 2, 20, { align: "center" });
  
    let currentY = 30; // Modified from 35 to maintain proportional spacing
  
    const checkAndAddPage = (y) => {
      if (y > doc.internal.pageSize.height - 20) {
        doc.addPage();
        return 20;
      }
      return y;
    };
  
    const renderSectionHeader = (title, y) => {
      doc.setFont(selectedFont, "bold"); // Bold font for section headers
      doc.setFontSize(parseInt(fontSize + 50)); // Larger size for section headers
      doc.text(title, leftMargin, y);
      doc.line(leftMargin, y + 2, rightMargin, y + 2); // Draw underline
      return y + 7;
    };
  
    // Example rendering of the Education section
    const renderEducation = (startY) => {
      let y = renderSectionHeader("EDUCATION", startY);
      combinedData.education.forEach((edu) => {
        y = checkAndAddPage(y);
        doc.setFont(selectedFont, "bold");
        doc.setFontSize(parseInt(fontSize) + 1 ); // Larger size for section headers
        doc.text(`${edu.university} | ${edu.location}`, leftMargin, y);
        doc.setFont(selectedFont, "normal");
        doc.text(formatDate(edu.graduation), rightMargin, y, { align: "right" });
        y += lineHeight;
  
        doc.setFont(selectedFont, "italic");        
        doc.setFontSize(parseInt(fontSize)); // Larger size for section headers
        const degreeLines = doc.splitTextToSize(edu.degree, pageWidth - 2 * leftMargin - 20);
        degreeLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin, y);
          y += lineHeight;
        });
  
        doc.setFont(selectedFont, "normal");
        const majorLines = doc.splitTextToSize(`Major: ${edu.major}`, pageWidth - 2 * leftMargin - 20);
        majorLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin, y);
          y += lineHeight;
        });
  
        const gpaLines = doc.splitTextToSize(`GPA: ${edu.gpa}`, pageWidth - 2 * leftMargin - 20);
        gpaLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin, y);
          y += lineHeight;
        });
  
        y += lineHeight * 0.5;
      });
      return y;
    };
  
    const renderExperience = (startY) => {
      let y = renderSectionHeader("EXPERIENCE", startY);
      combinedData.experience.forEach((exp) => {
        y = checkAndAddPage(y);
        doc.setFontSize(parseInt(fontSize) + 1 ); // Larger size for section headers
        doc.setFont(selectedFont, "bold");
        doc.text(`${exp.company} | ${exp.location}`, leftMargin, y);
        doc.setFont(selectedFont, "normal");
        doc.text(exp.duration, rightMargin, y, { align: "right" });
        y += lineHeight;
  
        doc.setFont(selectedFont, "italic");
        doc.setFontSize(parseInt(fontSize)); // Larger size for section headers

        doc.text(exp.jobTitle, leftMargin, y);
        y += lineHeight;
  
        doc.setFont(selectedFont, "normal");
        exp.description.forEach((desc) => {
          const lines = doc.splitTextToSize(
            /^[A-Za-z]/.test(desc) ? `• ${desc}` : desc,
            pageWidth - 2 * leftMargin - 20
          );
          lines.forEach((line) => {
            y = checkAndAddPage(y);
            doc.text(line, leftMargin + 5, y);
            y += lineHeight;
          });
        });
        y += lineHeight / 2;
      });
      return y;
    };
  
    const renderLeadership = (startY) => {
      let y = renderSectionHeader("LEADERSHIP ACTIVITIES AND MEMBERSHIPS", startY);
      combinedData.leadership.forEach((leader) => {
        y = checkAndAddPage(y);
        doc.setFontSize(11);
        doc.setFont(selectedFont, "bold");
        doc.setFontSize(parseInt(fontSize)+1); // Larger size for section headers
        doc.text(`${leader.organization} | ${leader.location}`, leftMargin, y);
        doc.setFont(selectedFont, "normal");
        doc.text(leader.duration, rightMargin, y, { align: "right" });
        y += lineHeight;
  
        doc.setFont(selectedFont, "italic");
        doc.setFontSize(parseInt(fontSize)); // Larger size for section headers
        doc.text(leader.title, leftMargin, y);
        y += lineHeight;
  
        doc.setFont(selectedFont, "normal");
        leader.accomplishment.forEach((acc) => {
          const lines = doc.splitTextToSize(
            /^[A-Za-z]/.test(acc) ? `• ${acc}` : acc,
            pageWidth - 2 * leftMargin - 20
          );
          lines.forEach((line) => {
            y = checkAndAddPage(y);
            doc.text(`${line}`, leftMargin + 5, y);
            y += lineHeight;
          });
        });
        y += lineHeight / 2;
      });
      return y;
    };
  
    const renderAwards = (startY) => {
      let y = renderSectionHeader("HONORS AND AWARDS", startY);
      combinedData.awards.forEach((award) => {
        y = checkAndAddPage(y);
        doc.setFontSize(11);
        doc.setFont(selectedFont, "normal");
  
        const awardText = `${award.award} | ${award.location}`;
        const awardLines = doc.splitTextToSize(awardText, pageWidth - 2 * leftMargin - 50);
        doc.text(awardLines, leftMargin, y);
  
        const dateText = `${formatDate2(award.date)}`;
        doc.text(dateText, rightMargin, y, { align: "right" });
  
        y += lineHeight * Math.max(awardLines.length, 1);
        y += lineHeight * 0.25;
      });
      return y;
    };
  
    const renderCertifications = (startY) => {
      let y = renderSectionHeader("CERTIFICATIONS", startY);
      combinedData.certifications.forEach((cert) => {
        y = checkAndAddPage(y);
        doc.setFontSize(parseInt(fontSize)); // Larger size for section headers
        doc.setFont(selectedFont, "normal");
  
        const certText = `${cert.certification_name} | ${cert.certification_location}`;
        const certLines = doc.splitTextToSize(certText, pageWidth - 2 * leftMargin - 50);
        doc.text(certLines, leftMargin, y);
  
        const yearText = `${cert.certification_year}`;
        doc.text(yearText, rightMargin, y, { align: "right" });
  
        y += lineHeight * Math.max(certLines.length, 1);
        y += lineHeight * 0.25;
      });
      return y;
    };
  
    const renderSkills = (startY) => {
      let y = renderSectionHeader("TECHNICAL AND LANGUAGE SKILLS", startY);
      doc.setFontSize(parseInt(fontSize)); // Larger size for section headers
      const rightMargin = 20;
  
      if (combinedData.skillsVisibility?.technical && combinedData.skills?.technical) {
        y = checkAndAddPage(y);
        doc.setFont(selectedFont, "bold");
        doc.text("Technical:", leftMargin, y);
        doc.setFont(selectedFont, "normal");
        const techLines = doc.splitTextToSize(combinedData.skills.technical, pageWidth - leftMargin - rightMargin - 20);
        techLines.forEach((line) => {
          y = checkAndAddPage(y);

          doc.text(line, leftMargin + 22, y, { maxWidth: pageWidth - leftMargin - rightMargin - 20 });
          y += lineHeight;
        });
        y += lineHeight;
      }
  
      if (combinedData.skillsVisibility?.languages && combinedData.skills?.languages) {
        y = checkAndAddPage(y);
        doc.setFont(selectedFont, "bold");
        doc.text("Language:", leftMargin, y);
        doc.setFont(selectedFont, "normal");
        const langLines = doc.splitTextToSize(combinedData.skills.languages, pageWidth - leftMargin - rightMargin - 20);
        langLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin + 22, y, { maxWidth: pageWidth - leftMargin - rightMargin - 20 });
          y += lineHeight;
        });
      }
  
      return y + lineHeight * 1.5;
    };
  
    const renderCustomSection = (startY, section) => {
      let y = renderSectionHeader(section.title, startY);
      doc.setFontSize(parseInt(fontSize)); // Larger size for section headers
      doc.setFont(selectedFont, "normal");
      const contentLines = doc.splitTextToSize(section.content, pageWidth - 2 * leftMargin - 20);
      contentLines.forEach((line) => {
        y = checkAndAddPage(y);
        doc.text(line, leftMargin, y);
        y += lineHeight;
      });
      return y + lineHeight;
    };
  
    const renderSection = (sectionName) => {
      currentY = checkAndAddPage(currentY);
      switch (sectionName) {
        case 'education':
          currentY = renderEducation(currentY);
          break;
        case 'experience':
          currentY = renderExperience(currentY);
          break;
        case 'leadership':
          if (!hiddenSections.leadership) currentY = renderLeadership(currentY);
          break;
        case 'awards':
          if (!hiddenSections.awards) currentY = renderAwards(currentY);
          break;
        case 'certifications':
          if (!hiddenSections.certifications) currentY = renderCertifications(currentY);
          break;
        case 'skills':
          if (!hiddenSections.skills) currentY = renderSkills(currentY);
          break;
        default:
          if (sectionName.startsWith('custom-')) {
            const index = parseInt(sectionName.split('-')[1]);
            currentY = renderCustomSection(currentY, combinedData.customSections[index]);
          }
          break;
      }
      currentY += lineHeight / 4; // Reduced extra space between sections
    };
  
    (combinedData.sectionOrder || [
      'education',
      'experience',
      'leadership',
      'awards',
      'certifications',
      'skills',
      ...combinedData.customSections.map((_, index) => `custom-${index}`)
    ]).forEach(renderSection);
  
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfUrl);
  };
  
  useEffect(() => {
    generatePDF();
  }, [formData, hiddenSections, font, fontSize]);

  

  return (
    <div style={{ fontFamily: font, fontSize: fontSize }}>
      {pdfUrl && (
        <iframe
          src={pdfUrl}
          width="100%"
          height="600px"
          title="Resume PDF Preview"
          className="mt-4 border border-gray-300"
        />
      )}
    </div>
  );
};

export default ResumePreviewWithPDF;