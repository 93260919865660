import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../comp/Sidebar'; // Replace MinimalSidebar with NewSidebar
import { supabase } from '../supabaseClient';
import { getAnswerFeedback } from '../comp/interviewFeedback';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardHeader from '../comp/Dashboardheader';
// Import UI components
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../comp/ui/card'; // Adjust the import path
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '../comp/ui/accordion';
import { Textarea } from '../comp/ui/textarea';
import { Button } from '../comp/ui/button';
import { CheckCircle2, MessageCircle, LogOut } from 'lucide-react'; // Adjust if necessary
import { Font } from 'pdfjs-dist/build/pdf.worker';

const InterviewQA = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [interviewData, setInterviewData] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [feedback, setFeedback] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null); // Initialize state to store the user
  const [isOpen, setIsOpen] = useState(false); // Sidebar state

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const uniqueCode = searchParams.get('code');
    if (uniqueCode) {
      fetchInterviewData(uniqueCode);
    }
  }, [location]);

  const fetchInterviewData = async (uniqueCode) => {
    try {
      const { data, error } = await supabase
        .from('interview_questions')
        .select('*')
        .eq('unique_code', uniqueCode)
        .single();

      if (error) throw error;

      setInterviewData(data);
      // Initialize userAnswers and feedback with saved data if any
      if (data.data.user_answers) {
        setUserAnswers(data.data.user_answers);
      }
      if (data.data.feedback) {
        setFeedback(data.data.feedback);
      }
    } catch (error) {
      console.error('Error fetching interview data:', error);
      toast.error('Failed to load interview data. Please try again.');
    }
  };

  const handleAnswerChange = (number, value) => {
    setUserAnswers((prev) => ({ ...prev, [number]: value }));
  };

  const handleGrade = async (questionNumber) => {
    setIsLoading(true);
    const question = interviewData.data.interview_questions.find(
      (q) => q.number === questionNumber
    );
    try {
      const result = await getAnswerFeedback(
        question.question,
        question.suggested_answer,
        userAnswers[questionNumber]
      );
      setFeedback((prev) => ({ ...prev, [questionNumber]: result }));

      // Save user answer and feedback to the database
      const updatedData = {
        ...interviewData.data,
        user_answers: {
          ...interviewData.data.user_answers,
          [questionNumber]: userAnswers[questionNumber],
        },
        feedback: {
          ...interviewData.data.feedback,
          [questionNumber]: result,
        },
      };

      const { error } = await supabase
        .from('interview_questions')
        .update({ data: updatedData })
        .eq('id', interviewData.id);

      if (error) throw error;

      toast.success('Answer graded successfully!');
    } catch (error) {
      console.error('Error grading answer:', error);
      toast.error('Failed to grade answer. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Error logging out.');
    }
  };

  if (!interviewData) {
    return <div>Loading...</div>;
  }

  const questions = interviewData.data.interview_questions || [];

  return (
    <div
      className="flex flex-col md:flex-row h-screen bg-gray-100"
      style={{ fontFamily: 'Poppins' }}
    >
      {/* Sidebar */}
      <Sidebar />
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
    
        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          <div className="container mx-auto p-4">
            <Card className="mb-6">
              <CardHeader>
                <CardTitle className="text-2xl font-bold">
                  Interview Preparation
                </CardTitle>
              </CardHeader>
              <CardContent>
                <h2 className="text-xl font-semibold mb-2">Job Details</h2>
                <p>
                  <strong>Title:</strong> {interviewData.job_data.jobTitle}
                </p>
                <p>
                  <strong>Company:</strong> {interviewData.job_data.company}
                </p>
              </CardContent>
            </Card>

            <Accordion type="single" collapsible className="w-full space-y-4">
              {questions.map((question) => (
                <AccordionItem
                  key={question.number}
                  value={`question-${question.number}`}
                  className="border rounded-lg">
                  <AccordionTrigger className="text-left px-4 py-2 hover:bg-gray-100">
                    <h4> {question.question} </h4>
                  </AccordionTrigger>
                  <AccordionContent className="p-4 space-y-6">
                    <Textarea
                      placeholder="Type your answer here..."
                      value={userAnswers[question.number] || ''}
                      onChange={(e) => handleAnswerChange(question.number, e.target.value)}
                      className="w-full min-h-[100px] h-24"
                    />
                    <div className="space-y-4">
                      <Button
                        onClick={() => handleGrade(question.number)}
                        disabled={isLoading || !userAnswers[question.number]}>
                        {isLoading ? 'Grading...' : 'Grade Answer'}
                      </Button>

                      {feedback[question.number] && (
                        <div className="bg-yellow-100 border border-yellow-300 rounded p-4">
                          <h4 className="font-semibold flex items-center">
                            <MessageCircle className="mr-2 h-5 w-5 text-yellow-500" />
                            Feedback:
                          </h4>
                          <p className="mt-2">{feedback[question.number].feedback}</p>
                          <p className="mt-2">
                            <strong>Score:</strong> {feedback[question.number].score}/5
                          </p>
                          <h5 className="font-semibold mt-2">Improvements:</h5>
                          <ul className="list-disc pl-5 mt-2">
                            {feedback[question.number].improvements.map((improvement, index) => (
                              <li key={index}>{improvement}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="bg-green-100 border border-green-300 rounded p-4">
                      <h4 className="font-semibold flex items-center">
                        <CheckCircle2 className="mr-2 h-5 w-5 text-green-500" />
                        Suggestions:
                      </h4>
                      <ul className="list-disc pl-5 mt-2">
                        {question.suggestions.map((suggestion, index) => (
                          <li key={index}>{suggestion}</li>
                        ))}
                      </ul>
                    </div>

                    <div className="bg-blue-100 border border-blue-300 rounded p-4">
                      <h4 className="font-semibold">Sample Answer:</h4>
                      <p className="mt-2">{question.suggested_answer}</p>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default InterviewQA;
