import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../comp/Sidebar';
import DashboardHeader from '../comp/Dashboardheader';
import { supabase } from '../supabaseClient';
import { generateInterviewQuestions } from '../comp/interviewGoogleAi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox } from '../comp/ui/checkbox';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../comp/ui/card'; 
import { Button } from '../comp/ui/button';
import { Input } from '../comp/ui/input';
import { Label } from '../comp/ui/label';
import { RadioGroup, RadioGroupItem } from '../comp/ui/radio-group';
import { Textarea } from '../comp/ui/textarea';
import { Loader2, Users, Briefcase, TrendingUp, LogOut } from 'lucide-react'; 

const Interview = () => {
  // State variables
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [employmentType, setEmploymentType] = useState('full-time');
  const [jobDescription, setJobDescription] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [user, setUser] = useState(null); // Initialize state to store the user
  const [isOpen, setIsOpen] = useState(false); // Sidebar state

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!jobTitle || !companyName || !employmentType || !jobDescription) {
      toast.error("Please fill in all fields.");
      return;
    }

    setIsGenerating(true);
    try {
      // Get the current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;

      // Prepare job details
      const jobDetails = {
        jobTitle,
        company: companyName,
        employmentType,
        description: jobDescription,
      };

      // Generate interview questions
      const aiResponse = await generateInterviewQuestions(jobDetails);

      if (!aiResponse || !aiResponse.interview_questions || aiResponse.interview_questions.length === 0) {
        throw new Error("No valid questions generated");
      }

      // Generate unique code
      const uniqueCode = Math.random().toString(36).substring(2, 15);

      // Save to Supabase
      const { error } = await supabase
        .from('interview_questions')
        .insert({
          user_id: user.id,
          unique_code: uniqueCode,
          job_data: jobDetails,
          data: aiResponse,
        });

      if (error) throw error;

      toast.success("Interview questions generated successfully!");
      // Navigate to a new page to display the questions
      navigate(`/interview-prep?code=${uniqueCode}`);
    } catch (error) {
      console.error('Error:', error);
      toast.error(`An error occurred: Please try again.`);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Error logging out.');
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100" style={{ fontFamily: "Poppins" }}>
      {/* Sidebar */}
      <Sidebar />
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
    
        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {isGenerating && (
            <div className="spinner-overlay">
              <div className="spinner"></div>
            </div>
          )}
          <div className="container mx-auto p-4 max-w-7xl">
            <div className="flex flex-col lg:flex-row gap-8">
              <div className="lg:w-2/3">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-2xl font-bold">AI-Powered Interview Architect</CardTitle>
                    <p>Craft the perfect set of interview questions tailored to your unique opportunity.

Unlock the power of AI to create targeted, insightful interview questions that will help you identify the best candidates for your role.
</p>
                  </CardHeader>
                  <CardContent>
                    <form onSubmit={handleSubmit}>
                      <div className="space-y-4">
                        <div className="space-y-2">
                          <Label htmlFor="jobTitle">Job Title</Label>
                          <Input
                            id="jobTitle"
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                            required
                          />
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor="companyName">Company Name</Label>
                          <Input
                            id="companyName"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="space-y-2">
                          <Label>Employment Type</Label>
                          <RadioGroup
                            value={employmentType}
                            onValueChange={setEmploymentType}
                            className="flex flex-col space-y-1"
                          >
                            <div className="flex items-center space-x-2">
                              <RadioGroupItem value="full-time" id="full-time" />
                              <Label htmlFor="full-time">Full-time</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                              <RadioGroupItem value="part-time" id="part-time" />
                              <Label htmlFor="part-time">Part-time</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                              <RadioGroupItem value="contract" id="contract" />
                              <Label htmlFor="contract">Contract</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                              <RadioGroupItem value="internship" id="internship" />
                              <Label htmlFor="internship">Internship</Label>
                            </div>
                          </RadioGroup>
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor="jobDescription">Job Description</Label>
                          <Textarea
                            id="jobDescription"
                            placeholder="Paste the job description here..."
                            value={jobDescription}
                            onChange={(e) => setJobDescription(e.target.value)}
                            className="min-h-[150px] h-24"
                            required
                          />
                        </div>
                      </div>
                      <CardFooter className="mt-4">
                        <Button
                          type="submit"
                          className="w-full"
                          disabled={isGenerating}
                        >
                          {isGenerating ? (
                            <>
                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                              Generating Questions...
                            </>
                          ) : (
                            "Generate Interview Questions"
                          )}
                        </Button>
                      </CardFooter>
                    </form>
                  </CardContent>
                </Card>
              </div>
              <div className="lg:w-1/3 space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-xl font-bold">Our Impact</CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="flex items-center space-x-4">
                      <Users className="h-8 w-8 text-blue-500" />
                      <div>
                        <p className="text-2xl font-bold">5,000+</p>
                        <p className="text-sm text-gray-500">Interviews Prepared</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <Briefcase className="h-8 w-8 text-green-500" />
                      <div>
                        <p className="text-2xl font-bold">75%</p>
                        <p className="text-sm text-gray-500">Success Rate</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <TrendingUp className="h-8 w-8 text-purple-500" />
                      <div>
                        <p className="text-2xl font-bold">30%</p>
                        <p className="text-sm text-gray-500">Salary Increase</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle className="text-xl font-bold">User Testimonial</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <blockquote className="italic text-gray-600">
                      "This interview prep tool was a game-changer for me. I felt so much more confident and landed my dream job!"
                    </blockquote>
                    <p className="mt-2 font-semibold">- Sarah T., Software Engineer</p>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Interview;
