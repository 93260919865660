import { GoogleGenerativeAI } from "@google/generative-ai";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

export async function generateLeadershipBullets(leadershipData) {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
    Given the following leadership experience information:
    Organization: ${leadershipData.organization}
    Leadership Title: ${leadershipData.title}
    Location: ${leadershipData.location}
    Duration: ${leadershipData.duration}
    Description: ${leadershipData.explanation}

    Generate 1-4 impactful bullet points describing leadership accomplishments and experiences. Each bullet point should showcase:
    1. Leadership initiative or project led (What)
    2. Impact and results achieved (Quantitative/Qualitative)
    3. Skills demonstrated or developed (How)

    Format should be professional and highlight leadership capabilities. Include metrics and specific outcomes where relevant.

    Return the bullet points as a JSON array of strings, with each string representing a single bullet point. Do not include any other text or formatting.
  `;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    // Parse the JSON array from the response
    const bulletPoints = JSON.parse(text);
    return bulletPoints;
  } catch (error) {
    console.error("Error generating leadership bullet points:", error);
    return [];
  }
}